/*
 * DO NOT EDIT THIS FILE DIRECTLY
 * This file is generated from the generate-iconset command.
 * See icon-master-list.js for instructions
 */

let i = 0;

/**
 * Easy selection for the icons for the environment
 **/
export const IconShape = {
  ACCOUNT_TREE: i++,
  ADD_CIRCLE: i++,
  ADD: i++,
  ARROW_DOWNWARD: i++,
  ARROW_LEFT: i++,
  ARROW_RIGHT: i++,
  AUTO_AWESOME: i++,
  BOLT: i++,
  CALENDAR_TODAY: i++,
  CHECK: i++,
  CIRCLE: i++,
  CLOSE: i++,
  CONTENT_COPY: i++,
  CREDIT_CARD: i++,
  DASHBOARD: i++,
  DELETE: i++,
  DRAG_INDICATOR: i++,
  EDIT_NOTE: i++,
  EDIT: i++,
  EMAIL: i++,
  FLAG: i++,
  THUMBS_UP: i++,
  FAVORITE: i++,
  FOOTBALL: i++,
  FILE_DOWNLOAD: i++,
  FILTER_LIST: i++,
  FOLDER: i++,
  FULLSCREEN: i++,
  GAMEPAD: i++,
  HAMBURGER_MENU: i++,
  HELP: i++,
  IMAGE: i++,
  INFO: i++,
  INSIGHTS: i++,
  KEYBOARD_ARROW_DOWN: i++,
  KEYBOARD_ARROW_LEFT: i++,
  KEYBOARD_ARROW_RIGHT: i++,
  KEYBOARD_ARROW_UP: i++,
  LABEL: i++,
  LIST: i++,
  LOCK: i++,
  LIGHTBULB: i++,
  LOGOUT: i++,
  MAIL: i++,
  PALETTE: i++,
  PERCENT: i++,
  PERSON: i++,
  PHONE: i++,
  PRIORITY_HIGH: i++,
  PUBLISH: i++,
  REDEEM: i++,
  REPLAY: i++,
  SEARCH: i++,
  SELL: i++,
  SETTINGS: i++,
  SHARE: i++,
  SPACE_DASHBOARD: i++,
  SPEED: i++,
  SPORTS_ESPORTS: i++,
  STAR: i++,
  SUBDIRECTORY_ARROW_RIGHT: i++,
  TITLE: i++,
  TERMINAL: i++,
  TRENDING_UP: i++,
  TRENDING_DOWN: i++,
  TRIANGLE_RIGHT: i++,
  TRIANGLE_DOWN: i++,
  UPLOAD_FILE: i++,
  WEB: i++,
  TEXT_BLOCK: i++,
  TEXT_INPUT: i++,
  TEXT_AREA: i++,
  CHECK_BOX: i++,
  RADIO_BUTTON: i++,
  SINGLE_SELECT: i++,
  LINE_ARROW_DOWN: i++,
  REPLAY_SMALL: i++,
  BROWSER: i++,
  FILE_CODE: i++,
  NEW_ICON_NAME: i++,
  MICROPHONE: i++,
  LINK: i++,
  PREV: i++,
  NEXT: i++,
  PLAY: i++,
  ALERT_EMPTY: i++,
  ALERT_FULL: i++,
  LOGO_FULL: i++,
  LOGO_SQUARE: i++,
  DOT_MENU: i++,
  TIKTOK: i++,
  FACEBOOK: i++,
  INSTAGRAM: i++,
  YOUTUBE: i++,
  X: i++,
} as const;

export type IconShapeType = (typeof IconShape)[keyof typeof IconShape];
