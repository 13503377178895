export default function ProfileImage({ fill = "#8B898E" }: { fill?: string }) {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.516325"
        y="0.500276"
        width="29"
        height="29"
        rx="14.5"
        transform="rotate(0.031686 0.516325 0.500276)"
        fill="#CADCE4"
      />
      <rect
        x="0.516325"
        y="0.500276"
        width="29"
        height="29"
        rx="14.5"
        transform="rotate(0.031686 0.516325 0.500276)"
        stroke="white"
      />
      <path
        d="M11.0351 17.3509C12.4152 16.7895 13.7368 16.5088 15 16.5088C16.2632 16.5088 17.5731 16.7895 18.9298 17.3509C20.3099 17.8889 21 18.6023 21 19.4912L21 21L9 21L9 19.4912C9 18.6023 9.67836 17.8889 11.0351 17.3509ZM17.1053 14.1228C16.5205 14.7076 15.8187 15 15 15C14.1813 15 13.4795 14.7076 12.8947 14.1228C12.3099 13.538 12.0175 12.8363 12.0175 12.0175C12.0175 11.1988 12.3099 10.4971 12.8947 9.91228C13.4795 9.30409 14.1813 9 15 9C15.8187 9 16.5205 9.30409 17.1053 9.91228C17.6901 10.4971 17.9825 11.1988 17.9825 12.0175C17.9825 12.8363 17.6901 13.538 17.1053 14.1228Z"
        fill="#717E85"
      />
    </svg>
  );
}
