export default function DotMenu({ fill = "#8B898E" }: { fill?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_468_23392"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="10"
        width="12"
        height="4"
      >
        <path
          d="M10.9474 10.4561C11.2515 10.152 11.6023 10 12 10C12.3977 10 12.7485 10.152 13.0526 10.4561C13.3567 10.7602 13.5088 11.1111 13.5088 11.5088C13.5088 11.9064 13.3567 12.2573 13.0526 12.5614C12.7485 12.8655 12.3977 13.0175 12 13.0175C11.6023 13.0175 11.2515 12.8655 10.9474 12.5614C10.6433 12.2573 10.4912 11.9064 10.4912 11.5088C10.4912 11.1111 10.6433 10.7602 10.9474 10.4561ZM15.4386 10.4561C15.7427 10.152 16.0936 10 16.4912 10C16.8889 10 17.2398 10.152 17.5439 10.4561C17.848 10.7602 18 11.1111 18 11.5088C18 11.9064 17.848 12.2573 17.5439 12.5614C17.2398 12.8655 16.8889 13.0175 16.4912 13.0175C16.0936 13.0175 15.7427 12.8655 15.4386 12.5614C15.1345 12.2573 14.9825 11.9064 14.9825 11.5088C14.9825 11.1111 15.1345 10.7602 15.4386 10.4561ZM6.45614 10.4561C6.76023 10.152 7.11111 10 7.50877 10C7.90643 10 8.25731 10.152 8.5614 10.4561C8.8655 10.7602 9.01754 11.1111 9.01754 11.5088C9.01754 11.9064 8.8655 12.2573 8.5614 12.5614C8.25731 12.8655 7.90643 13.0175 7.50877 13.0175C7.11111 13.0175 6.76023 12.8655 6.45614 12.5614C6.15205 12.2573 6 11.9064 6 11.5088C6 11.1111 6.15205 10.7602 6.45614 10.4561Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_468_23392)">
        <rect width="24" height="24" fill={fill} />
      </g>
    </svg>
  );
}
