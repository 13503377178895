export default function Share({ fill = "#8B898E" }: { fill?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_468_23451"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="3"
        width="12"
        height="17"
      >
        <path
          d="M14.9825 6.01754L13.9298 7.07018L12.7368 5.87719V14.2281H11.2632V5.87719L10.0702 7.07018L9.01754 6.01754L12 3L14.9825 6.01754ZM18 9.73684V17.9825C18 18.4035 17.848 18.7661 17.5439 19.0702C17.2632 19.3509 16.9123 19.4912 16.4912 19.4912H7.50877C7.08772 19.4912 6.72515 19.3509 6.42105 19.0702C6.14035 18.7661 6 18.4035 6 17.9825V9.73684C6 9.33918 6.14035 9 6.42105 8.7193C6.72515 8.4152 7.08772 8.26316 7.50877 8.26316H9.75439V9.73684H7.50877V17.9825H16.4912V9.73684H14.2456V8.26316H16.4912C16.9123 8.26316 17.2632 8.4152 17.5439 8.7193C17.848 9 18 9.33918 18 9.73684Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_468_23451)">
        <rect width="24" height="24" fill={fill} />
      </g>
    </svg>
  );
}
